.head {
  display: flex;
}
.heading {
  width: 55%;
  font-size: 38px;
}
hr {
  border: none;
  margin: auto;
  height: 5px;
  width: 400px;
  background-color: #fdae54;
}
.images {
  margin: 100px 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 33% 33% 33%;
  overflow: hidden;
  justify-content: center;
  align-content: center;
}
.i1 {
  width: 100%;
  align-self: center;
  justify-self: center;
  height: 100%;
}
.i2 {
  width: 100%;
  justify-self: center;
  height: 100%;
}
.i3 {
  width: 100%;
  justify-self: center;
  height: 100%;
}
.i4 {
  width: 100%;
  justify-self: center;
  height: 100%;
}
.i5 {
  width: 100%;
  justify-self: center;
  height: 100%;
}
.i6 {
  width: 100%;
  justify-self: center;
  height: 100%;
}
.i7 {
  width: 100%;
  height: 100%;
  justify-self: center;
}
.i8 {
  width: 100%;
  height: 100%;
  justify-self: center;
}
.i9 {
  width: 100%;
  height: 100%;
  justify-self: center;
}
.i1:hover {
  transform: scale(1.1);
}
.i2:hover {
  transform: scale(1.1);
}
.i3:hover {
  transform: scale(1.1);
}
.i4:hover {
  transform: scale(1.1);
}
.i5:hover {
  transform: scale(1.1);
}
.i6:hover {
  transform: scale(1.1);
}
.i7:hover {
  transform: scale(1.1);
}
.i8:hover {
  transform: scale(1.1);
}
.i9:hover {
  transform: scale(1.1);
}
@media all and (max-width: 1250px) {
  .images {
    grid-gap: 20px;
    grid-template-columns: 45% 45%;
  }
}
@media all and (max-width: 1155px) {
  .images {
    margin-top: 60px;
  }
  .heading {
    width: 60%;
    font-size: 34px;
  }
}
@media all and (max-width: 1000px) {
  .heading {
    width: 90%;
    font-size: 30px;
  }
}
@media all and (max-width: 850px) {
  hr {
    display: none;
  }
}
@media all and (max-width: 700px) {
  .images {
    grid-template-columns: 100%;
  }
  .i7 {
    display: none;
  }
  .i8 {
    display: none;
  }
  .i9 {
    display: none;
  }
}
@media all and (max-width: 582px) {
  .heading {
    width: 100%;
    font-size: 28px;
  }
}
@media all and (max-width: 500px) {
  .images {
    margin-top: 20px;
  }
  .heading {
    font-size: 22px;
  }
}
@media all and (max-width: 400px) {
  .heading {
    font-size: 19px;
  }
}
