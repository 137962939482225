* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Crimson Text", serif;
}

.App {
  padding: 20px 40px;
}

@media all and (max-width: 1200px) {
  .App {
    padding: 15px;
  }
}
