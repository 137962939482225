.main {
  margin: 100px 0;
  display: flex;
}
.right {
  margin-left: 150px;
  display: grid;
  grid-template-columns: auto auto;
}
.img {
  width: 600px;
}
h2 {
  align-items: center;
  display: flex;
}
p {
  font-size: 30px;
  font-weight: lighter;
}
.head {
  font-size: 38px;
  grid-column: 1/3;
}
.g {
  font-size: 30px;
}
.g1 {
  display: flex;
  grid-column: 1/2;
  grid-row: 2;
}
.g2 {
  font-size: 30px;
  display: flex;
  grid-column: 2/3;
  grid-row: 2;
}
.g3 {
  font-size: 30px;
  display: flex;
  grid-column: 1/2;
  grid-row: 3;
}
.g4 {
  font-size: 30px;
  display: flex;
  grid-column: 2/3;
  grid-row: 3;
}
.g5 {
  font-size: 30px;
  display: flex;
  grid-column: 1/2;
  grid-row: 4;
}
.g6 {
  font-size: 30px;
  display: flex;
  grid-column: 2/3;
  grid-row: 4;
}

@media all and (max-width: 1300px) {
  .right {
    margin-left: 120px;
  }
  .img {
    width: 550px;
  }
}
@media all and (max-width: 1200px) {
  .right {
    margin-left: 120px;
  }
  .img {
    width: 500px;
  }
  .head {
    font-size: 35px;
  }
  p {
    font-size: 27px;
  }
  .g {
    font-size: 27px;
  }
}
@media all and (max-width: 1100px) {
  .main {
    margin: 60px 0;
  }
  .right {
    margin-left: 80px;
  }
  .head {
    font-size: 32px;
  }
  p {
    font-size: 25px;
  }
  .g {
    font-size: 25px;
  }
}
@media all and (max-width: 1000px) {
  .img {
    width: 400px;
  }
}
@media all and (max-width: 900px) {
  .right {
    margin-left: 50px;
  }
  .head {
    font-size: 26px;
  }
  p {
    font-size: 21px;
  }
  .g {
    font-size: 21px;
  }
}
@media all and (max-width: 800px) {
  .main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin: 20px;
  }
  .right {
    margin: 60px 0;
    height: 200px;
  }
}
@media all and (max-width: 590px) {
}
