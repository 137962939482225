.main {
  margin-top: 20px;
  padding: 100px 120px;
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
}
.hand,
.medal,
.pen {
  width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pen,
.medal {
  margin-top: 100px;
}
.hand-logo,
.medal-logo,
.pen-logo {
  width: 80px;
}
.h2 {
  margin-top: 10px;
  font-size: 30px;
}
.para {
  font-size: 15px;
}

@media all and (max-width: 1300px) {
  .main {
    padding: 100px 90px;
  }
}
@media all and (max-width: 1200px) {
  .main {
    padding: 90px 70px;
  }
}
@media all and (max-width: 1100px) {
  .main {
    padding: 80px 50px;
  }
  .hand-logo,
  .medal-logo,
  .pen-logo {
    width: 60px;
  }
  .h2 {
    font-size: 25px;
  }
}

@media all and (max-width: 1000px) {
  .main {
    padding: 50px 30px;
  }
  .h2 {
    font-size: 23px;
  }
  .para {
    font-size: 13px;
  }
}
@media all and (max-width: 900px) {
  .main {
    padding: 30px 10px;
  }
  .h2 {
    font-size: 23px;
  }
  .para {
    font-size: 13px;
  }
}
@media all and (max-width: 600px) {
  .main {
    padding: 20px 0;
  }
}
@media all and (max-width: 500px) {
  .main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hand,
  .medal,
  .pen {
    padding: 0;
    margin: 10px;
  }
}
