.circle {
  position: absolute;
  right: 0;
  z-index: -100;
}
.main {
  position: relative;
}
.circle2 {
  position: absolute;
  top: 200px;
  right: 500px;
}
.circle3 {
  position: absolute;
  top: 400px;
  left: 300px;
}
.header {
  display: flex;
  align-items: center;
}
.line {
  position: relative;
  height: 2px;
  flex: 0.4;
  margin: 20px;
}
.head {
  margin-left: 20px;
  font-size: 40px;
  flex: 0.6;
}
.flex {
  padding: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.head2 {
  font-size: 25px;
}
.para {
  text-align: center;
  font-size: 20px;
  width: 200px;
}

@media all and (max-width: 1100px) {
  .flex {
    padding: 50px;
  }
  .head {
    font-size: 35px;
  }
}
@media all and (max-width: 850px) {
  .head {
    margin-left: 0;
    font-size: 32px;
    flex: 1;
  }
  .flex {
    padding: 0;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .para {
    font-size: 15px;
  }
}

@media all and (max-width: 680px) {
  .flex {
    flex-direction: column;
  }
  .div {
    margin-bottom: 40px;
  }
}
@media all and (max-width: 500px) {
  .head {
    font-size: 28px;
  }
  .circle {
    display: none;
  }
}
@media all and (max-width: 395px) {
  .head {
    font-size: 24px;
  }
}
