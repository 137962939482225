.header-body {
  display: flex;
  justify-content: space-between;
}
.right {
  margin-top: 50px;
}
.img {
  height: 600px;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hed1 {
  font-size: 60px;
}
.para {
  font-weight: 400;
  font-size: 15px;
  width: 90%;
  margin-top: 30px;
}
@media all and (max-width: 1275px) {
  .hed1 {
    font-size: 50px;
  }
}
@media all and (max-width: 1200px) {
  .img {
    height: 500px;
  }
}

@media all and (max-width: 1100px) {
  .hed1 {
    font-size: 40px;
  }
  .img {
    height: 450px;
  }
}

@media all and (max-width: 880px) {
  .hed1 {
    font-size: 33px;
  }
  .para {
    font-size: 13px;
  }
  .img {
    height: 400px;
  }
}

@media all and (max-width: 770px) {
  .header-body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .left {
    padding: 70px 20px;
  }
  .right {
    display: none;
  }
}

@media all and (max-width: 500px) {
  .left {
    padding: 60px 10px;
  }
}
@media all and (max-width: 401px) {
  .hed1 {
    font-size: 30px;
  }
  .left {
    padding: 50px 0;
  }
  .para {
    width: 100%;
  }
}
