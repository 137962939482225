.div {
  position: relative;
  margin-top: 80px;
  margin-bottom: 150px;
}
.head {
  margin: 50px auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head2 {
  margin-top: 50px;
  margin-right: 20px;
  font-size: 40px;
}
.btn {
  margin-top: 50px;
}
.image {
  position: relative;
}
.over {
  border-radius: 2px;
  margin-left: 30px;
  padding: 20px;
  width: 270px;
  background-color: #f1f1f1;
  position: absolute;
  bottom: -50px;
  transition: all 0.2s;
}
.over:hover {
  background-color: #414141;
  color: white;
  transform: scale(1.1);
}
.para {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: lighter;
}
.span {
  color: #fdae54;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media all and (max-width: 1100px) {
  .head {
    margin: 50px 30px;
  }
  .image {
    flex: 0.2;
    width: 100%;
  }
}
@media all and (max-width: 1000px) {
  .div {
    display: none;
  }
}
