.main {
  background-color: #f1f1f1;
  padding: 50px;
  display: flex;
  justify-content: space-around;
}
.flex1 {
  display: flex;
}
.h1 {
  font-size: 22px;
}
.ul {
  list-style-type: none;
  font-weight: lighter;
  font-size: 20px;
  margin: 30px 0;
}
.li {
  padding: 5px;
  cursor: pointer;
}
.text {
  padding: 20px;
  border: none;
  outline: none;
  font-size: 17px;
  height: 50px;
  width: 300px;
}
.btn {
  margin: 20px 0;
  background: #fdae54;
  border: none;
  outline: none;
  color: white;
  font-size: 17px;
  height: 50px;
  width: 300px;
  cursor: pointer;
}
.flex {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 1100px) {
  .main {
    padding: 0px;
    padding-top: 50px;
  }
  .h1 {
    font-size: 20px;
  }
  .ul {
    font-size: 18px;
  }
}

@media all and (max-width: 1000px) {
  .main {
    margin-top: 40px;
  }
}

@media all and (max-width: 860px) {
  .main {
    flex-direction: column;
    justify-content: center;
  }
  .flex1 {
    width: 100%;
    justify-content: space-around;
  }
  .flex2 {
    margin: auto;
  }
}
@media all and (max-width: 540px) {
  .h1 {
    font-size: 18px;
  }
  .ul {
    font-size: 16px;
  }
}
